html {
  -webkit-touch-callout: none;
}

body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@font-face {
  font-family: 'Noka';
  src: local('Noka'), url(./fonts/Noka.woff) format('woff');
}

@font-face {
  font-family: 'MierA-Book';
  src: local('MierA'), url(./fonts/MierA.woff) format('woff');
}

@font-face {
  font-family: 'Platform-Medium';
  src: local('Platform-Medium'),
    url(./fonts/Platform-Medium-Web.woff) format('woff');
}
@font-face {
  font-family: 'Platform-Regular';
  src: local('Platform-Bold'),
    url(./fonts/Platform-Bold-Web.woff) format('woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* #root {
  height: 100%;
  min-height: inherit;
} */

.marker-label {
  /* border: 2px solid hotpink; */
  font-weight: 800;
  font-size: '30px';
}

.gm-style-cc {
  opacity: 0;
}

.gm-style-moc {
  background: rgba(120, 100, 100, 0.6);
}

.gm-style-mot {
  font-size: 18px;
}
